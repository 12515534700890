import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_ADDON_DETAILS,
        ADD_ADDON_DETAILS,
        UPDATE_ADDON_DETAILS,
        DELETE_ADDON_DETAILS,
        DELETE_AGENT_ADDON_DETAILS
    } = URLS;
    return {
        getAddonDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'post', GET_ADDON_DETAILS, body, "agent"),
        addAddonDetails: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', ADD_ADDON_DETAILS, body, "agent"),
        updateAddonDetails: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_ADDON_DETAILS.replace(":_id", body._id), body, "agent"),
        deleteAddonDetails: (body = {}, method = 'delete') =>
            performRequest(method ? method : 'delete', DELETE_ADDON_DETAILS.replace(":_id", body._id), body),
        deleteAgentAddonDetails: (body = {}, method = 'delete') =>
            performRequest(method ? method : 'delete', DELETE_AGENT_ADDON_DETAILS.replace(":_id", body._id), body, "agent")

    }
}