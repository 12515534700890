import demoLogo from "./logo/logo.png";
import summerbaylogo from "../assets/img/logo/sbay-app-logo.png"

let  logo = demoLogo

let applicationName = "Travoxios Digital Intelligence Platform (TDIP)"

let siteName = "https://travoxis.com/"

let FooterName = "Travoxios (1165017-V)"



export {
    logo,
    applicationName,
    siteName,
    FooterName
}
