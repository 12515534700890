import axios from "axios";
import { generateAppConfig } from "../config";
require("dotenv").config();

export const API_BASE_URL = generateAppConfig().backendUrl;
export const AGENT_API_BASE_URL = generateAppConfig().agentBackendUrl;
export const USER_URL = generateAppConfig().userPortalBackendUrl;
export const FILE_UPLOAD_URL = process.env.REACT_APP_FILE_UPLOAD_URL;

export const performRequest = async (method, url, params, urlFrom="admin") => {
  let body = "data";
  if (method === "get") {
    body = "params";
  }
  const baseURL = urlFrom === "admin"? API_BASE_URL: urlFrom === "userPortal"?USER_URL:AGENT_API_BASE_URL;
  const accessToken = JSON.parse(localStorage.getItem("accessToken") || "{}");
  const config = {
    method,
    url,
    baseURL: baseURL,
    [body]: params || {},
  };
  config.headers = {
    "Content-Type": "application/json; charset=utf-8",
    Authorization: "Bearer " + accessToken.accessToken,

  };
  return axios.request(config);

  // let body = "data";
  // if (method === "get") {
  //   body = "params";
  // }
  // if (typeof spId !== "undefined") {
  //   url = url.replace(":_id", spId);
  // }
  // if (url === "/travelagent-info/:_id" && method === "put") {
  //   console.log(params, 'jjj');
  //   url = url.replace(":_id", params._id);
  // }
  // if (method === "put" || method === "delete") {
  //   if (url === "/travelagent-info/:_id" && method === "put") {
  //     url = url.replace(":_id", params.userId);

  //   } else {
  //     url = url.replace(":_id", params._id);
  //   }
  // }
  // if (url === "/agent-email-file/:_id" && method === "delete" || method === "put") {
  //   url = url.replace(":_id", params._id);
  // }
  // if (url === "/travelagent-info/:_id" && method === "post") {
  //   url = url.replace(":_id", params._id);
  // }
  // if (url === "/travelagent-info/:_id" && method === "get") {
  //   url = url.replace(":_id", params._id);
  // }

  // if (url === `/reservation/${spId}/payment/:paymentid` && method === "put") {
  //   url = url.replace(":paymentid", params.payId);
  // }

  // if (url && url.includes('/:date')) {
  //   url = url.replace(":date", date);
  // }
  
  // }
};
