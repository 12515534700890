import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_TRAVELAGENT_REGISTRATION_DETAILS,ADD_TRAVELAGENT_REGISTRATION_DETAILS,UPDATE_TRAVELAGENT_REGISTRATION_DETAILS,DELETE_TRAVELAGENT_REGISTRATION_DETAILS,UPDATE_TRAVELAGENT_REGISTRATION_STATUS,GET_TRAVELAGENT_REGISTRATION_STATUS_OPTIONS } = URLS;
    return {
        getTravelAgentRegistrationDetails: (body={}, method='get') =>
         performRequest( method ? method:'post', GET_TRAVELAGENT_REGISTRATION_DETAILS, body ),
        addTravelAgentRegistrationDetails: (body={}, method='post') =>
         performRequest( method ? method:'post', ADD_TRAVELAGENT_REGISTRATION_DETAILS, body ),
        updateTravelRegistrationAgentDetails: (body={}, method='put') =>
         performRequest( method ? method:'put', UPDATE_TRAVELAGENT_REGISTRATION_DETAILS, body ),
        deleteTravelRegistrationAgentDetails: (body={}, method='delete') =>
         performRequest( method ? method:'delete', DELETE_TRAVELAGENT_REGISTRATION_DETAILS, body ),
         updateTravelRegistrationAgentStatus: (body={}, method='put') =>
         performRequest( method ? method:'put', UPDATE_TRAVELAGENT_REGISTRATION_STATUS,body ),
         getStatus: (body={}, method='get') =>
         performRequest( method ? method:'get', GET_TRAVELAGENT_REGISTRATION_STATUS_OPTIONS,body )

    }
}




