import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_ROOM_CODE,CHANGE_ROOM, FILE_UPLOAD, GET_ROOMTYPE_DETAILS, ADD_ROOMTYPE_DETAILS, UPDATE_ROOMTYPE_DETAILS, DELETE_ROOMTYPE_DETAILS, GET_ONE_ROOMTYPE_DETAILS,UPDATE_ROOM_TYPE_PRIORITY,GET_ROOMS_BY_ROOMTYPES, GET_ROOM_AVAILABLE_BEDCOMBO } = URLS;
    return {
        fileUpload: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', FILE_UPLOAD, body),
        getRoomTypeDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'post', GET_ROOMTYPE_DETAILS, body),
        addRoomTypeDetails: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', ADD_ROOMTYPE_DETAILS, body),
        updateRoomTypeDetails: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_ROOMTYPE_DETAILS, body),
        deleteRoomTypeDetails: (body = {}, method = 'delete') =>
            performRequest(method ? method : 'delete', DELETE_ROOMTYPE_DETAILS, body),
        getRoomCodeDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', `/room-code-availability/${body}`),
        upadteRoomTypePriorityDetails: (body = {}, method = 'put') =>
            performRequest(method ? method : 'put', UPDATE_ROOM_TYPE_PRIORITY,body),
        getRoomByRoomTypes: (body = {}, method = 'get',url) =>
            performRequest(method ? method : 'get', GET_ROOMS_BY_ROOMTYPES,body),
        getReservationPerPeriod: (body = {}, method = 'get',url) =>
            performRequest(method ? method : 'get',url,body),
        getOccupancyPercentage: (body = {}, method = 'get',url) =>
            performRequest(method ? method : 'get',url,body),
        getRoomTypeCombos: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', GET_ROOM_AVAILABLE_BEDCOMBO, body),  
        changeRoom: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', CHANGE_ROOM.replace(":resId",body.resId), body),  

    }
}




