import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'
import moment from "moment"

export default () => {
    const {GET_RESERVATION_INFO,
     SET_RESERVATION_PAYMENT_STATUS,
     GET_PAYMENT_STATUS,
     GET_TRANS_REPORT,
     GET_APPROVED_RESERVATION_DATA,
     AGENT_RESERVATION_CANCEL_BOOKING,
     AGENT_RESERVATION_CANCEL__APPROVED_BOOKING,
     GET_WALLET,
     BLOCK_PRMO_CODE,
     BOOK_PRMO_CODE,
     WALLET_PRMOCODE,
     PAY_PROMO_PACKAGE_FROM_WALLET,
     PAY_EVENT_FROM_WALLET,
     EDIT_INFO
         } = URLS;
    return {
         updateNotes: (body={}, method='put',id) =>
         performRequest( method ? method:'put', EDIT_INFO.replace(":_id",body._id), body, 'agent'),
         payFromWalletForPackage:(body={}, method='post') =>
         performRequest( method ? method:'post', PAY_PROMO_PACKAGE_FROM_WALLET, body, 'agent' ),
         payFromWalletForEvent:(body={}, method='post') =>
         performRequest( method ? method:'post', PAY_EVENT_FROM_WALLET, body, 'agent' ),
         walletPrmoCode:(body={}, method='POST',id) =>
         performRequest( method ? method:'POST', WALLET_PRMOCODE, body, 'agent'),
         blockPrmoCode: (body={}, method='POST',id) =>
         performRequest( method ? method:'POST', BLOCK_PRMO_CODE, body, 'agent'),
         bookPrmoCode: (body={}, method='POST',id) =>
         performRequest( method ? method:'POST', BOOK_PRMO_CODE, body, 'agent'),
         getWallet: (body={}, method='get',) =>
         performRequest('get', GET_WALLET, body,"agent"),
         getReservationDetails: (body={}, method='get',id) =>
         performRequest( method ? method:'get', GET_RESERVATION_INFO.replace(":_id", id), body, "agent" ),
         getApprovedReservationDetails: (body={}, method='get',id) =>
         performRequest( method ? method:'get', GET_APPROVED_RESERVATION_DATA.replace(":_id", id), body, "agent" ),
         changePaymentStatus: (body={}, method='put',id,paymentId) =>
         performRequest( method ? method:'put', SET_RESERVATION_PAYMENT_STATUS, body,'',id ),
         changeReservationStatus: (body={}, method='put',id) =>
         performRequest( method ? method:'put', GET_RESERVATION_INFO.replace(":_id", id), body ),
         updateRefundAmount: (body={}, method='put',id) =>
         performRequest( method ? method:'put', GET_RESERVATION_INFO, body,'',id ),
         extendBookingDate: (body={}, method='put',id) =>
         performRequest( method ? method:'put', GET_RESERVATION_INFO, body ),
         guistListChange: (body={}, method='put',id) =>
         performRequest( method ? method:'put', GET_RESERVATION_INFO, body ),
         getPaymentStatus: (body={}, method='get') =>
         performRequest( method ? method:'get', GET_PAYMENT_STATUS.replace(":_id", body._id), body, "agent" ),
         getTransReport: (body={}, method='get', date) =>
         performRequest( method ? method:'get', GET_TRANS_REPORT, body,"", "", date ),
         cancelReservation: (body={}, method='put', id) =>
         performRequest( method ? method:'put', `${AGENT_RESERVATION_CANCEL_BOOKING.replace(":_id", id)}?type=${body?.type}`, body,"agent"),
         cancelApprovedReservation: (body={}, method='put', id) =>
            performRequest( method ? method:'put', AGENT_RESERVATION_CANCEL__APPROVED_BOOKING.replace(":_id", id), body,"agent"),
    
    }
}