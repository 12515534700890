import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_BANNERS, GET_AGENT_BANNERS, GET_SINGLE_BANNERS } = URLS;
    return {
        getBanners: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_BANNERS, body, "agent"),
        getAgentBanners: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', `${GET_AGENT_BANNERS}${body.agentId}`, body, "agent"),
        getSingleBanner: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', `${GET_SINGLE_BANNERS}`, body, "agent"),
        saveBanners:(body = {}, method = 'post') =>
            performRequest(method ? method : 'post', `${GET_BANNERS}`, body, "agent"),
        updateBanners:(body = {}, method = 'put') =>
            performRequest(method ? method : 'put', `${GET_BANNERS}?_id=${body._id}`, body, "agent"),
    
    }
}