import React from "react";
import * as Icon from "react-feather";
import moment from "moment"

const horizontalMenuConfig = 
[
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    navLink: "/",
    icon: <Icon.Home size={16} />,
   
  },
  {
    id: "booking",
    title: "Bookings",
    type: "dropdown",
    icon: <Icon.Grid size={16} />,
    children: [
      {
        id: "newbooking",
        title: "New Booking",
        type: "item",
        icon: <Icon.Mail size={16} />,
        navLink: "/bookings/create",
        permissions: ["admin", "editor"],
      },
      {
        id: "bookingList",
        title: "My Bookings",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/booking/listing",
        permissions: ["admin", "editor"],
      },
      {
        id: "unApprovedBookingList",
        title: "Awaiting Approvals",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/booking/unApprovedBookingList",
        permissions: ["admin", "editor"],
      }
    ]
  },
  {
    id: "promotion",
    title: "Promotions",
    type: "item",
    navLink: "/promotion",
    icon: <Icon.Home size={16} />,
   
  },
  {
    id: "myvouchers",
    title: "My Vouchers",
    type: "item",
    navLink: "/my-promotions",
    icon: <Icon.Home size={16} />,
   
  },
  {
    id: "referralPage",
    title: "Referral",
    type: "item",
    navLink: "/referral-page",
    icon: <Icon.Home size={16} />,
  },
  {
    id: "paymentHistory",
    title: "Payment History",
    type: "item",
    navLink: "/payment-history",
    icon: <Icon.Home size={16} />,
  },
  {
    id: "appsettings",
    title: "Settings",
    type: "dropdown",
    icon: <Icon.Settings size={16} />,
    children: [
      {
        id: "myAddons",
        title: "My Addons",
        type: "item",
        navLink: "/activities/addon",
        icon: <Icon.Home size={16} />,       
      },
      {
        id: "Banners",
        title: "My Promotional Banner",
        type: "item",
        navLink: "/my-banners",
        icon: <Icon.Home size={16} />,       
      }
    ]
  },
  // {
  //   id: "credit",
  //   title: "Credits",
  //   type: "item",
  //   navLink: "/credits",
  //   icon: <Icon.Home size={16} />,   
  // },
  
];

export default horizontalMenuConfig;
