import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { AGENT_INFO,
    } = URLS;
    return {
        getAgentBankDetails: (body={}, method='get') =>
         performRequest( method ? method:'get', AGENT_INFO, body, "agent" ),
        updateAgentBankDetails:(body = {}, method = 'put') =>
            performRequest(method ? method : 'put', AGENT_INFO, body, "agent"),
           
    }
}