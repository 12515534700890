import React from "react"
import {
  //NavItem,
  //NavLink,
  UncontrolledDropdown,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge
} from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import axios from "axios"
import * as Icon from "react-feather"
//import classnames from "classnames"
import ReactCountryFlag from "react-country-flag"
//import Autocomplete from "../../../components/@vuexy/autoComplete/AutoCompleteComponent"
import { useAuth0 } from "../../../authServices/auth0/auth0Service"
import { history } from "../../../history"
import { IntlContext } from "../../../utility/context/Internationalization"
import Currency from './CurrencyComponent'
import { reservationService } from "../../../service";
import { getWalletInfo } from "../../../redux/actions/wallet/action.wallet";
import { connect } from "react-redux"

const handleNavigation = (e, path) => {
  e.preventDefault()
  history.push(path)
}

const UserDropdown = props => {
  const { logout, isAuthenticated } = useAuth0()
  return (
    <DropdownMenu right>
      {/* <DropdownItem
        tag="a"
        href="#"
        onClick={e => handleNavigation(e, "/pages/profile")}
      >
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Edit Profile</span>
      </DropdownItem>
      <DropdownItem
        tag="a"
        href="#"
        onClick={e => handleNavigation(e, "/email/inbox")}
      >
        <Icon.Mail size={14} className="mr-50" />
        <span className="align-middle">My Inbox</span>
      </DropdownItem>
      <DropdownItem
        tag="a"
        href="#"
        onClick={e => handleNavigation(e, "/todo/all")}
      >
        <Icon.CheckSquare size={14} className="mr-50" />
        <span className="align-middle">Tasks</span>
      </DropdownItem>
      <DropdownItem
        tag="a"
        href="#"
        onClick={e => handleNavigation(e, "/chat")}
      >
        <Icon.MessageSquare size={14} className="mr-50" />
        <span className="align-middle">Chats</span>
      </DropdownItem>
      <DropdownItem tag="a" href="#" onClick={e => handleNavigation(e, "/ecommerce/wishlist")}>
        <Icon.Heart size={14} className="mr-50" />
        <span className="align-middle">WishList</span>
      </DropdownItem> */}
      {/* <DropdownItem divider /> */}

      <DropdownItem
        tag="a"
        onClick={()=>{ history.push("/agent/profile")}}        
      >
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Profile</span>
      </DropdownItem>

      <DropdownItem
        tag="a"
        onClick={()=>{ history.push("/credits")}}        
      >
        <Icon.CheckSquare size={14} className="mr-50" />
        <span className="align-middle">Credits</span>
      </DropdownItem>

      <DropdownItem
        tag="a"
        onClick={()=>{ history.push("/documents")}}        
      >
        <Icon.File size={14} className="mr-50" />
        <span className="align-middle">Documents</span>
      </DropdownItem>

      <DropdownItem
        tag="a"
        href="/"
        onClick={e => {
          localStorage.clear("accesToken")
        }}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
      
    </DropdownMenu>
  )
}

class NavbarUser extends React.PureComponent {
  static getDerivedStateFromProps(props, state) {
    return {
      walletAmount:  (props?.wallet?.creditedAmount? (props?.wallet?.creditedAmount - props?.wallet?.debitedAmount - props?.wallet?.totalReqAmount).toLocaleString(undefined, { minimumFractionDigits: 2 }) : 0)
    }
    return null
  }
  state = {
    navbarSearch: false,
    langDropdown: false,
    langCurrencyDropdown: false,    
    shoppingCart: [
      {
        id: 1,
        name:
          "Apple - Apple Watch Series 1 42mm Space Gray Aluminum Case Black Sport Band - Space Gray Aluminum",
        desc:
          "Durable, lightweight aluminum cases in silver, space gray, gold, and rose gold. Sport Band in a variety of colors. All the features of the original Apple Watch, plus a new dual-core processor for faster performance. All models run watchOS 3. Requires an iPhone 5 or later.",
        price: "$299",
        img: require("../../../assets/img/pages/eCommerce/4.png"),
        width: 75
      },
      {
        id: 2,
        name:
          "Apple - Macbook® (Latest Model) - 12' Display - Intel Core M5 - 8GB Memory - 512GB Flash Storage Space Gray",
        desc:
          "MacBook delivers a full-size experience in the lightest and most compact Mac notebook ever. With a full-size keyboard, force-sensing trackpad, 12-inch Retina display,1 sixth-generation Intel Core M processor, multifunctional USB-C port, and now up to 10 hours of battery life,2 MacBook features big thinking in an impossibly compact form.",
        price: "$1599.99",
        img: require("../../../assets/img/pages/eCommerce/dell-inspirion.jpg"),
        width: 100,
        imgClass: "mt-1 pl-50"
      },
      {
        id: 3,
        name: "Sony - PlayStation 4 Pro Console",
        desc:
          "PS4 Pro Dynamic 4K Gaming & 4K Entertainment* PS4 Pro gets you closer to your game. Heighten your experiences. Enrich your adventures. Let the super-charged PS4 Pro lead the way.** GREATNESS AWAITS",
        price: "$399.99",
        img: require("../../../assets/img/pages/eCommerce/7.png"),
        width: 88
      },
      {
        id: 4,
        name:
          "Beats by Dr. Dre - Geek Squad Certified Refurbished Beats Studio Wireless On-Ear Headphones - Red",
        desc:
          "Rock out to your favorite songs with these Beats by Dr. Dre Beats Studio Wireless GS-MH8K2AM/A headphones that feature a Beats Acoustic Engine and DSP software for enhanced clarity. ANC (Adaptive Noise Cancellation) allows you to focus on your tunes.",
        price: "$379.99",
        img: require("../../../assets/img/pages/eCommerce/10.png"),
        width: 75
      },
      {
        id: 5,
        name:
          "Sony - 75' Class (74.5' diag) - LED - 2160p - Smart - 3D - 4K Ultra HD TV with High Dynamic Range - Black",
        desc:
          "This Sony 4K HDR TV boasts 4K technology for vibrant hues. Its X940D series features a bold 75-inch screen and slim design. Wires remain hidden, and the unit is easily wall mounted. This television has a 4K Processor X1 and 4K X-Reality PRO for crisp video. This Sony 4K HDR TV is easy to control via voice commands.",
        price: "$4499.99",
        img: require("../../../assets/img/pages/eCommerce/sony-75class-tv.jpg"),
        width: 100,
        imgClass: "mt-1 pl-50"
      },
      {
        id: 6,
        name:
          "Nikon - D810 DSLR Camera with AF-S NIKKOR 24-120mm f/4G ED VR Zoom Lens - Black",
        desc:
          "Shoot arresting photos and 1080p high-definition videos with this Nikon D810 DSLR camera, which features a 36.3-megapixel CMOS sensor and a powerful EXPEED 4 processor for clear, detailed images. The AF-S NIKKOR 24-120mm lens offers shooting versatility. Memory card sold separately.",
        price: "$4099.99",
        img: require("../../../assets/img/pages/eCommerce/canon-camera.jpg"),
        width: 70,
        imgClass: "mt-1 pl-50"
      }
    ],
    suggestions: [],
    walletAmount: 0
  }

  componentDidMount() {
    
    this.props.getWalletInfo();
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch
    })
  }

  removeItem = id => {
    let cart = this.state.shoppingCart

    let updatedCart = cart.filter(i => i.id !== id)

    this.setState({
      shoppingCart: updatedCart
    })
  }

  handleLangDropdown = () =>
    this.setState({ langDropdown: !this.state.langDropdown })

     
  handleLangCurrencyDropdown = () =>{   
    this.setState({ langCurrencyDropdown: !this.state.langCurrencyDropdown })
    }
  render() {
    // const renderCartItems = this.state.shoppingCart.map(item => {
    //   return (
    //     <div className="cart-item" key={item.id}>
    //       <Media
    //         className="p-0"
    //         onClick={() => history.push("/ecommerce/product-detail")}
    //       >
    //         <Media className="text-center pr-0 mr-1" left>
    //           <img
    //             className={`${
    //               item.imgClass
    //                 ? item.imgClass + " cart-item-img"
    //                 : "cart-item-img"
    //             }`}
    //             src={item.img}
    //             width={item.width}
    //             alt="Cart Item"
    //           />
    //         </Media>
    //         <Media className="overflow-hidden pr-1 py-1 pl-0" body>
    //           <span className="item-title text-truncate text-bold-500 d-block mb-50">
    //             {item.name}
    //           </span>
    //           <span className="item-desc font-small-2 text-truncate d-block">
    //             {item.desc}
    //           </span>
    //           <div className="d-flex justify-content-between align-items-center mt-1">
    //             <span className="align-middle d-block">1 x {item.price}</span>
    //             <Icon.X
    //               className="danger"
    //               size={15}
    //               onClick={e => {
    //                 e.stopPropagation()
    //                 this.removeItem(item.id)
    //               }}
    //             />
    //           </div>
    //         </Media>
    //       </Media>
    //     </div>
    //   )
    // })
    console.log(this.props,'---props---------')
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        {/*  LANGUAGE SWITCH OPTION  */}
        <IntlContext.Consumer>
          {context => {
            let langArr = {
              "en": "English",
              "my": "Malay",
              "cn": "Chinese",   
            }
            return (
              // <Dropdown
              //   tag="li"
              //   className="dropdown-language nav-item"
              //   isOpen={this.state.langDropdown}
              //   toggle={this.handleLangDropdown}
              //   data-tour="language"
              // >
              //   <DropdownToggle
              //     tag="a"
              //     className="nav-link"
              //   >
              //     <ReactCountryFlag
              //     className="country-flag"
              //       countryCode={
              //         context.state.locale === "en"
              //           ? "us"
              //           : context.state.locale
              //       }
              //       svg
              //     />
              //     <span className="d-sm-inline-block d-none text-capitalize align-middle ml-50">
              //       {langArr[context.state.locale]}
              //     </span>
              //   </DropdownToggle>
              //   <DropdownMenu right>
              //     <DropdownItem
              //       tag="a"
              //       onClick={e => context.switchLanguage("en")}
              //     >
              //       <ReactCountryFlag className="country-flag" countryCode="us" svg />
              //       <span className="ml-1">English</span>
              //     </DropdownItem>
              //     <DropdownItem
              //       tag="a"
              //       onClick={e => context.switchLanguage("my")}
              //     >
              //       <ReactCountryFlag className="country-flag" countryCode="my" svg />
              //       <span className="ml-1">Malay</span>
              //     </DropdownItem>
              //     <DropdownItem
              //       tag="a"
              //       onClick={e => context.switchLanguage("cn")}
              //     >
              //       <ReactCountryFlag className="country-flag" countryCode="cn" svg />
              //       <span className="ml-1">Chinese</span>
              //     </DropdownItem>
                 
              //   </DropdownMenu>
              // </Dropdown>
               <Dropdown
                tag="li"
                className="dropdown-language  border-left border-right nav-item d-flex align-items-center pl-50"
                isOpen={this.state.langCurrencyDropdown}
                toggle={this.handleLangCurrencyDropdown}
                data-tour="language"
              >
                <DropdownToggle
                  tag="a"
                  className="nav-link mr-0 p-50"
                >                  
                  <span className="d-sm-inline-block align-middle">
                  {langArr[context.state.locale]}
                  </span> {' - '}
                  <span className="d-sm-inline-block align-middle">
                   {context.state.currency}
                  </span>
                  <Icon.ChevronDown       
                    size={15} className="ml-50"
                  />
                </DropdownToggle>
                <DropdownMenu right>
                  <div className="p-1" style={{minWidth:"250px"}}>
                    <div className="reg-setting-heading mb-2">
                      <h6 className="font-weight-bold">Regional Setting</h6>
                    </div>
                    <div className="form-group">
                      <label className="mb-50">Language</label>
                      <select className="custom-select" value = {context.state.locale} onChange = {(e) =>context.switchLanguage(e.target.value) }>
                        <option value ="en">English</option>
                        <option value ="my">Malay</option>
                        <option value = "cn">Chinese</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label  className="mb-50">Currency</label>
                      {/* <select className="custom-select">
                        <option>USD-US Dollar</option>
                        <option>MYR-Malaysian Ringgit</option>
                        <option>INR-Indian Rupee</option>
                        <option>SGD-Singapore Dollar</option>
                        <option>CNY-China Yuan</option>
                      </select> */}
                       <Currency toggle={this.handleLangCurrencyDropdown}/>
                    </div>
                    <div className="text-right">
                      <button type="button" className="btn btn-primary btn-sm">Save</button>
                    </div>
                  </div>
                </DropdownMenu>
              </Dropdown>

            )
          }}
        </IntlContext.Consumer>
        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
        </UncontrolledDropdown>
        <UncontrolledDropdown tag="li" 
          className="dropdown-user nav-item d-flex align-items-center px-50 border-right cursor-pointer"
          onClick={()=>{ history.push("/credits")}}
        >
          <span>
              <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12"></path>
                  <path d="M20 12v4h-4a2 2 0 0 1 0 -4h4"></path>
              </svg>
          </span>
          <div className="ml-50">
              <div className="mb-75 d-none d-sm-block">My Account</div>
              <div className="">
                  <b>RM {this.state.walletAmount}</b>
              </div>
          </div>
        </UncontrolledDropdown>
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item d-flex align-items-center">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link p-0 pl-50">
            {/* <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.props.userName}
              </span>
            </div> */}
            <span data-tour="user">
              <img
                src={this.props.userImg}
                className="round"
                height="40"
                width="40"
                alt="avatar"
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    )
  }
}
const mapStateToProps = state => {
  return {
    wallet: state.wallet.walletDetails.data
  }
}

export default connect(mapStateToProps, {
  getWalletInfo
})(NavbarUser)
